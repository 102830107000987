import React from "react";
import { motion } from "framer-motion";
import "./pages.css";
import as from "./as1.png";
import Footer from "./footer.js";


const Home = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
     <div className="header">
        <div className="logo">
          <img src={as} alt="Logo" className="home-img" />
        </div>
        <div className="title">
          <h1>Welcome to VirMortalis Guild</h1>
        </div>
      </div>
      <div className="container-text">
        <h2>- VirMortalis -</h2>
        <p>
        Welcome to Vir Mortalis, a guild dedicated to fostering a cooperative and inclusive community on the Silvermoon server in World of Warcraft. Our guild stands as a bastion of camaraderie, where players from all walks of life come together to embark on epic adventures, tackle challenging content, and create lasting memories.
        </p>
        <p>
        <h2>- Core Values -</h2>

        <li>Cooperation and Teamwork: At the heart of the Vir-Mortalis is a commitment to teamwork. We believe that the greatest victories are achieved when we work together, supporting each other through every quest, dungeon, and raid. Whether you're a seasoned veteran or a newcomer, your contribution is valued and essential to our collective success.</li>

<li>Toxicity-Free Environment: We pride ourselves on maintaining a positive and welcoming atmosphere. Toxic behavior has no place in our guild. We promote respect, kindness, and constructive communication, ensuring that every member feels safe and appreciated. Our guild chat and Discord channels are places where you can freely share your experiences, seek advice, and build friendships without fear of harassment or negativity.</li>
<li>Community Focus: Beyond the game, we are a community of friends. Regular events, both in-game and out, help strengthen our bonds. From guild meetings and fun runs to social gatherings and voice chat hangouts, there's always something happening to keep our community engaged and connected.</li>

       </p>
      </div>
      
      <Footer></Footer>
    </motion.div>
  );
};

export default Home;
