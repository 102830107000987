import React from "react";
import { BrowserRouter as Router,  Route, Routes, useLocation } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Prog from "./Pages/prog";
import Recruit from "./Pages/recruit";

import "./Pages/pages.css";
import {AnimatePresence} from "framer-motion";

function AnimatedRoutes() {
    const location = useLocation();

    return (
    <AnimatePresence>
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/prog" element={<Prog />} />
            <Route path="/recruit" element={<Recruit />} />
            
        </Routes>
    </AnimatePresence> 
    );


}

export default AnimatedRoutes;