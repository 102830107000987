import React from "react";
import "./pages.css";

const Footer = () => {
    return (
        <div className="footer-content">
          {/* Config for all */}
          <p>Reach us:</p>
          <div className="social-icons">
            {/* add more if needed */}
            <a href="https://discord.gg/virmortalis" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-discord"></i>
            </a>
            <a href="mailto:web@vir-core.cloud">
              <i className="fas fa-envelope"></i>
            </a>
          </div>
        </div>
    );
  };
  
  export default Footer;