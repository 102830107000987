import React from "react";
import { motion } from "framer-motion";
import "./pages.css";
import Footer from "./footer";
import aotc1 from "./aotc1.png"


const Recruit = () => {
    return (
        <motion.div
          className="container"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="header">
            <div className="title">
              <h1>Join Our Guild - Recruitment</h1>
            </div>
          </div>
    
          <div className="container-text">
            <h2>Our Teams</h2>
            <p>We are recruiting for two dedicated teams within VirMortalis. Read more below about each team's focus, roles, and what we're looking for in new recruits.</p>
    
            {/* Main content split into two sections */}
            <div className="teams-container">
              <div className="team-section">
                <h3>Team 1 - Semi Hardcore</h3>
                <p>
                  <strong>About Team 1:</strong> Team 1 is focused on high-level raid content tackle Mythic and Heroic content.
                </p>
                <ul>
            
                  <li>Raid Times: Tuesdays and Thursdays, 21:00 - 23:00 Server Time</li>
                  <li>Requirements: Level 80, previous raid experience preferred</li>
                  <li>
                        <a href="https://apply.wowaudit.com/eu/silvermoon/virmortalis/raid-team-1?preview" target="_blank" rel="noopener noreferrer">
                        Apply here!
                        </a>
                    </li>
                </ul>
              </div>
    
              <div className="team-section">
                <h3>Team 2 - Casual</h3>
                <p>
                  <strong>About Team 2:</strong> Team 2 focuses on casual content and enjoys PvE in a relaxed environment. We’re looking for players who want to have fun and enjoy the game with friends without the pressure of progression-focused raids.
                </p>
                <ul>
                  <li>Event Times: Saturdays, 21:00 - 23:00 Server time</li>
                  <li>Requirements: Level 80+, all skill levels welcome</li>
                  <li>
                        <a href="https://apply.wowaudit.com/eu/silvermoon/virmortalis/raid-team-2?preview" target="_blank" rel="noopener noreferrer">
                        Apply here!
                        </a>
                    </li>
                </ul>
                <h3>Not a raider?</h3>
                <p>
                  <strong>Guild members</strong> If you are not looking into raids, you can always find your place with us, you can participate on our Mythic plus night, we also host a couple events per week to make sure people engage with the guild, plus the usual back and support from everyone.
                </p>
                <ul>
                  <li>Event Times: Saturdays, 21:00 - 23:00 Server time</li>
                  <li>Requirements: Level 80+, all skill levels welcome</li>
                  
                </ul>
              </div>
            </div>
          </div>
    
          <Footer />
        </motion.div>
      );
    };

export default Recruit;
