import React from "react";
import { motion, px } from "framer-motion";
import "./pages.css";
import as from "./as1.png";
import Footer from "./footer";

/* Adding staff pict*/
import a from "./staff/a.png";
import v from "./staff/v.png";
import m from "./staff/m.png";
import d from "./staff/d.png";
import b from "./staff/b.png";
import n from "./staff/n.png";
import i from "./staff/i.png";

const staff = [
  { name: "Blaspheme", image: b , desc: "Guild Master, not sure what he does"},
  { name: "Alice", image: a , desc: "One of our founder, currently handling our Raid team 2"},
  { name: "Valadin aka EdgeMaster", image: v , desc: "The EdgeMaster takes care of our Progression team" },
  { name: "Cadeuceus", image: m , desc: "Joined us a little after the rebirth, handle the insane part of keeping track of everyone"},
  { name: "Dali", image: d , desc: "One of our founder, ran out of heal classes to play, got bored still suport us as best as he can"},
  { name: "Noodle", image: n , desc: "number one founder, still trying to find the boat"},
  { name: "Invictus", image: i , desc: "The one that keep everything runing, hard not to get a whisper from him"},
];


const Home = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
     <div className="header">
        <div className="logo">
          <img src={as} alt="Logo" className="home-img" />
        </div>
        <div className="title">
          <h1>...we are The Guild</h1>
        </div>
      </div>
      <div className="container-text">
        <h2>About Us</h2>
        <p>
        Vir-Mortalis was born from team spirit of friends who shared a passion for adventure and a commitment to building a community where every member is valued. Our guild is focused on creating a positive and inclusive environment where players of all backgrounds can thrive, have fun, and grow together.
        </p>
        <p>
        At Vir-Mortalis, we believe that a strong guild is built on respect, teamwork, and a shared love for the game. Our mission is to foster an environment free from toxicity, where players can experience World of Warcraft in a relaxed and supportive atmosphere. Whether you're new to the game or a seasoned veteran, there’s a place for you in our guild.
        </p>
        <ul>
        <li><strong>Inclusivity and Support: </strong>We’re proud to be a diverse guild that respects and supports all members, regardless of skill level or experience.</li> 
        <li><strong>Teamwork and Cooperation: </strong>Our success comes from working together, both in and out of raids. We support each other in dungeons, raids, PvP, and casual gameplay.</li> 
        <li><strong>Learning and Growth: </strong>We value each player’s journey, offering a welcoming space to learn, improve, and achieve new milestones.</li> 
        <li><strong>Have fun: </strong>Beyond the game, we’re a close-knit community of friends. From guild events and voice chat hangouts to social gatherings, we’re here to enjoy every moment together.</li>
        </ul>
        <p>
         </p>
         <div className="staff-section">
          <h2>Meet Our Staff</h2>
          <div className="staff-list">
            {staff.map((officer, index) => (
              <div key={index} className="staff-member">
                <img src={officer.image} alt={officer.name} className="staff-img"/>
                <p>{officer.name}</p>
                <p className="staff-desc">{officer.desc}</p>
              </div>
            ))}
          </div>
        </div>


      </div>
      <Footer></Footer>
    </motion.div>
  );
};

export default Home;
