import React from "react";
import { motion } from "framer-motion";
import "./pages.css";
import Footer from "./footer";
import aotc1 from "./aotc1.png";

const Prog = () => {
  return (
    <motion.div
      className="container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Introduction Section */}
      <header className="header-section">
        <h1>Guild Raid Achievements</h1>
        <p className="intro-text">
          Welcome to our hall of fame! Here, we honor the victories, teamwork, and dedication of Vir-Mortalis as we conquer the most challenging raids in Azeroth.
        </p>
      </header>

      {/* Achievement Section */}
      <div className="container-text">
        <h2>Nerub'ar Palace - Ahead of the Curve</h2>
        <p className="achievement-description">
          This monumental achievement marked a turning point for our guild, showcasing our strength, unity, and skill as we overcame every obstacle to reach the finish line.
        </p>
        
       
          <div className="milestone">
          <img src={aotc1} alt="Ahead of the Curve - Nerub'ar Palace" className="achievement-img" /> </div>
      
      </div>

      {/* Footer */}
      <Footer />
    </motion.div>
  );
};

export default Prog;
